<template>
  <table
    v-if="claimsProxy !== undefined"
    class="tw-min-w-full tw-divide-y tw-divide-gray-200 tw-border-b tw-border-gray-200"
  >
    <thead>
      <tr class="">
        <th
          :class="[
            'tw-px-4 tw-py-3 tw-bg-gray-100 tw-text-xs tw-leading-4 tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider tw-text-center tw-w-1/5 tw-border-b-4 tw-border-b-solid tw-cursor-pointer',
            sortedStyle('company.name'),
          ]"
          @click="setSortColumn('company', 'name', 'string')"
        >
          Company
        </th>

        <th
          :class="[
            'tw-px-4 tw-py-3 tw-bg-gray-100 tw-text-left tw-text-xs tw-leading-4 tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider  tw-text-center tw-w-48 tw-border-b-4 tw-border-b-solid tw-cursor-pointer',
            sortedStyle('undefined.name'),
          ]"
          @click="setSortColumn(undefined, 'start_date', 'string')"
        >
          Period
        </th>
        <th
          :class="[
            'tw-px-4 tw-py-3 tw-bg-gray-100 tw-text-left tw-text-xs tw-leading-4 tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider  tw-text-center tw-w-8 tw-border-b-4 tw-border-b-solid'
          ]"
        >
          Onboarded?
        </th>
        <th
          :class="[
            'tw-px-4 tw-py-3 tw-bg-gray-100 tw-text-left tw-text-xs tw-leading-4 tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider tw-w-8 tw-border-b-4 tw-border-b-solid tw-cursor-pointer',
            sortedStyle('status.green'),
          ]"
          @click="sortByRowColour('status', 'green')"
        >
          <Tippy content="Sort by if status is ready for review">
            Status
          </Tippy>
        </th>

        <th
          :class="[
            'tw-px-4 tw-py-3 tw-bg-gray-100 tw-text-left tw-text-xs tw-leading-4 tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider tw-w-8 tw-border-b-4 tw-border-b-solid tw-cursor-pointer',
            sortedStyle('projects.green'),
          ]"
          @click="sortByRowColour('projects', 'green')"
        >
          <Tippy content="Sort by if all projects are ready for review">
            Proj
          </Tippy>
        </th>

        <th
          :class="[
            'tw-px-4 tw-py-3 tw-bg-gray-100 tw-text-left tw-text-xs tw-leading-4 tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider tw-w-8 tw-border-b-4 tw-border-b-solid tw-cursor-pointer',
            sortedStyle('costs.green'),
          ]"
          @click="sortByRowColour('costs', 'green')"
        >
          <Tippy content="Sort by if cost is ready for review">
            Costs
          </Tippy>
        </th>
        <th
          :class="[
            'tw-px-4 tw-py-3 tw-bg-gray-100 tw-text-left tw-text-xs tw-leading-4 tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider tw-w-8 tw-border-b-4 tw-border-b-solid tw-cursor-pointer',
            sortedStyle('rad.green'),
          ]"
          @click="sortByRowColour('rad', 'green')"
        >
          <Tippy content="Sort by if cost is ready for review and cost has R&D">
            R&D
          </Tippy>
        </th>
        <th
          :class="[
            'tw-px-4 tw-py-3 tw-bg-gray-100 tw-text-left tw-text-xs tw-leading-4 tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider tw-w-8 tw-border-b-4 tw-border-b-solid tw-cursor-pointer',
            sortedStyle('fds.green'),
          ]"
          @click="sortByRowColour('fds', 'green')"
        >
          <Tippy content="Sort by if finance document is ready for review">
            FDs
          </Tippy>
        </th>

        <th
          :class="[
            'tw-px-2 tw-py-3 tw-bg-gray-100 tw-text-xs tw-leading-4 tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider tw-text-center tw-border-b-4 tw-border-b-solid tw-cursor-pointer',
            sortedStyle('undefined.claim_value'),
          ]"
          @click="setSortColumn(undefined, 'claim_value', 'number')"
        >
          <i class="fas fa-pound-sign tw-text-center tw-mr-2" />
        </th>

        <th
          :class="[
            'tw-px-2 tw-py-3 tw-bg-gray-100 tw-text-xs tw-leading-4 tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider tw-text-center tw-border-b-4 tw-border-b-solid tw-cursor-pointer',
            sortedStyle('undefined.claim_progress'),
          ]"
          @click="setSortColumn(undefined, 'claim_progress', 'number')"
        >
          <i class="fas fa-percent tw-text-center tw-mr-2" />
        </th>
        <th
          :class="[
            'tw-px-2 tw-py-3 tw-bg-gray-100 tw-text-xs tw-leading-4 tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider tw-text-center tw-border-b-4 tw-border-b-solid tw-cursor-pointer',
            sortedStyle('undefined.last_user_activity_time_as_epoch'),
          ]"
          @click="sortLastUserActivity()"
        >
          <Tippy content="When was a user last active on this claim">
            <i class="fas fa-user-clock tw-text-center tw-mr-2" />
          </Tippy>
        </th>

        <th
          class="
            tw-px-4
            tw-py-3
            tw-bg-gray-100
            tw-text-xs
            tw-leading-4
            tw-font-medium
            tw-text-gray-500
            tw-uppercase
            tw-tracking-wider
            tw-text-center
          "
        />

        <th
          class="
            tw-px-4
            tw-py-3
            tw-bg-gray-100
            tw-text-xs
            tw-leading-4
            tw-font-medium
            tw-text-gray-500
            tw-uppercase
            tw-tracking-wider
            tw-text-center
          "
        />
      </tr>
    </thead>

    <tbody>
      <template
        v-for="[idx, cp] in Object.entries(claimsProxy).filter(
          (x) =>
            x[1].company.name.toUpperCase().indexOf(query.toUpperCase()) > -1
        )"
        :key="cp.slug"
      >
        <tr :class="idx % 2 == 0 ? 'tw-bg-white' : 'tw-bg-gray-50'">
          <td
            class="
              tw-px-1
              tw-py-2
              tw-whitespace-no-wrap
              tw-text-sm
              tw-leading-5
              tw-text-gray-500
            "
          >
            <div class="hover-trigger tw-flex tw-items-center tw-w-100">
              <div class="tw-flex tw-gap-x-2 tw-items-center">
                <div
                  clas="tw-cursor-pointer"
                  @click="toggleFlag(cp, !cp.flagged)"
                >
                  <Tippy
                    :content="
                      cp.flagged
                        ? 'This Claim has been flagged by a CSM'
                        : 'Flag this Claim'
                    "
                    delay="500"
                  >
                    <i
                      :class="`fas fa-exclamation-triangle fa-1x ${flagCssClass(
                        cp.flagged
                      )}`"
                    />
                  </Tippy>
                </div>

                <Tippy
                  :allow-h-t-m-l="true"
                  :content="companyAmlCheckText(cp)"
                  :interactive="true"
                >
                  <div
                    title="Company AML Check Result"
                    :class="[
                      'tw-text-gray-300 tw-mr-2',
                      companyAmlCheckColor(cp.company),
                    ]"
                  >
                    <i class="fas fa-dot-circle fa-1x" />
                  </div>
                </Tippy>
              </div>

              <div class="tw-w-44 tw-inline-flex tw-gap-x-2">
                <a
                  target="_blank"
                  :href="`/companies/${cp.company.slug}`"
                  class="tw-truncate"
                  v-text="cp.company.name"
                />
                <a
                  target="_blank"
                  :href="`/companies/${cp.company.slug}/edit`"
                  class="hover-target"
                >
                  <i class="fas fa-pencil fa-1x tw-text-gray-500" />
                </a>
              </div>
            </div>
          </td>

          <td
            :class="{
              'tw-px-4 tw-py-2 tw-whitespace-no-wrap tw-text-sm tw-leading-5 tw-text-gray-500': true,
              'tw-bg-green-100': cp.ready_for_review,
            }"
          >
            <div class="hover-trigger tw-flex tw-items-center tw-gap-x-2 tw-w-100">
              <a
                target="_blank"
                :href="`/claim_periods/${cp.slug}`"
                class=""
                v-text="cp.title"
              />
              <tooltip v-if="cp.post_aug_2023_workflow">
                <i class="fas fa-flag-alt fa-1x tw-text-cta-blue" />
                <template #content>
                  <div class="tw-p-1 tw-space-y-2">
                    <div class="tw-font-semibold tw-text-sm">
                      This Claim is part of the new claim workflow for the August legislation guidelines and the Additional Information Form.
                    </div>
                  </div>
                </template>
              </tooltip>

              <div class="tw-inline-flex tw-items-center tw-gap-x-1.5">
                <a target="_blank" :href="`/claim_periods/${cp.slug}/edit`" class="hover-target">
                  <i class="fas fa-pencil fa-1x tw-text-gray-500" />
                </a>

                <a
                  target="_blank"
                  :href="`/claim_periods/${cp.slug}/full_claim_view`"
                >
                  <i class="fas fa-file-alt fa-1x tw-text-gray-500" />
                </a>

                <a v-show="cp.has_failing_severe_quality_check == true" :href="`/claim_periods/${cp.slug}`" target="_blank">
                  <Tippy content="Claim has failing high severity quality checks">
                    <i class="fas fa-engine-warning fa-1x tw-text-red-500" />
                  </Tippy>
                </a>
              </div>
            </div>
          </td>

          <td
            :class="{
              'tw-px-4 tw-py-2 tw-whitespace-no-wrap tw-text-sm tw-leading-5 tw-text-gray-500 tw-text-center': true,
            }"
          >
            <i :class="onboardedIcon(cp.onboarded)" />
          </td>

          <!-- Status -->
          <td
            :class="[
              'tw-px-4 tw-py-2 tw-whitespace-no-wrap tw-text-sm tw-leading-5 tw-text-gray-500 tw-text-center',
              rowColoursGreen['status'](cp)
                ? 'tw-bg-green-100'
                : 'tw-bg-red-100',
            ]"
          >
            <div
              v-show="cp.status.slug !== undefined"
              class="tw-flex tw-items-center"
            >
              <div class="tw-w-1/3">
                <span
                  class="tw-text-xs tw-font-bold tw-text-gray-500"
                  v-text="cp.type"
                />
              </div>
              <div class="tw-w-2/3">
                <a
                  target="_blank"
                  :href="`/statuses/${cp.status.slug}/build/status`"
                >
                  <i class="fas fa-pencil fa-1x tw-text-gray-500" />
                </a>
              </div>
              <div v-show="cp.status.slug === undefined">
                <a target="_blank" :href="`/statuses/new?cp_id=${cp.id}`">
                  <i class="fas fa-plus fa-1x tw-text-gray-300" />
                </a>
              </div>
            </div>

            <div v-show="cp.status.slug === undefined">
              <a
                target="_blank"
                :href="`/statuses/new?cp_id=${cp.id}&company_slug=${cp.company.slug}`"
              >
                <i class="fas fa-plus fa-1x tw-text-gray-300" />
              </a>
            </div>
          </td>

          <!-- projects -->
          <td
            :class="[
              'tw-py-2 tw-whitespace-no-wrap tw-text-sm tw-leading-5 tw-text-gray-500 tw-text-center',
              rowColoursGreen['projects'](cp) ? 'tw-bg-green-100' : 'tw-bg-red-100',
            ]"
          >
            <Tippy
              :content="projectsPopup(cp)"
              :allow-h-t-m-l="true"
              :interactive="true"
            >
              <span class="tw-cursor-pointer" v-text="cp.projects.length" />
            </Tippy>
            <Tippy content="Manage Projects">
              <a target="_blank" :href="`/claim_periods/${cp.slug}/manage_projects`">
                <i class="fas fa-pencil fa-1x tw-text-gray-500 tw-ml-2" />
              </a>
            </Tippy>
            <Tippy v-if="cp.new_project_methodology" content="This claim is on the New Project Methodology">
              <span class="tw-text-error-red tw-text-lg">*</span>
            </Tippy>
          </td>

          <!-- costs -->
          <td
            :class="[
              'tw-px-4 tw-py-2 tw-whitespace-no-wrap tw-text-sm tw-leading-5 tw-text-gray-500 tw-text-center',
              rowColoursGreen['costs'](cp) ? 'tw-bg-green-100' : 'tw-bg-red-100',
            ]"
          >
            <div v-show="cp.cost.slug !== undefined">
              <a target="_blank" :href="`/claim_periods/${cp.slug}/#cost`">
                <i class="fas fa-search fa-1x tw-text-gray-500 tw-mr-1" />
              </a>
              <a target="_blank" :href="`/costs/${cp.cost.slug}/edit`">
                <i class="fas fa-pencil fa-1x tw-text-gray-500 tw-mr-1" />
              </a>
            </div>

            <div v-show="cp.cost.slug === undefined">
              <a target="_blank" :href="`/costs/new?cp_id=${cp.id}`">
                <i class="fas fa-plus fa-1x tw-text-gray-300" />
              </a>
            </div>
          </td>

          <!-- R&D -->
          <td
            :class="[
              'tw-px-4 tw-py-2 tw-whitespace-no-wrap tw-text-sm tw-leading-5 tw-text-gray-500 tw-text-center',
              rowColoursGreen['rad'](cp) ? 'tw-bg-green-100' : 'tw-bg-red-100',
            ]"
          >
            <div v-show="cp.cost.slug !== undefined">
              <a target="_blank" :href="`/add_rd/${cp.cost.slug}`">
                <i class="fas fa-pencil fa-1x tw-text-gray-500" />
              </a>
            </div>

            <div v-show="cp.cost.slug === undefined">
              <a target="_blank" :href="`/costs/new?cp_id=${cp.id}`">
                <i class="fas fa-plus fa-1x tw-text-gray-300" />
              </a>
            </div>
          </td>

          <!-- Finance Docs -->
          <td
            :class="[
              'tw-px-4 tw-py-2 tw-whitespace-no-wrap tw-text-sm tw-leading-5 tw-text-gray-500 tw-text-center',
              rowColoursGreen['fds'](cp) ? 'tw-bg-green-100' : 'tw-bg-red-100',
            ]"
          >
            <div
              v-show="cp.finance_document.slug !== undefined"
              class="tw-flex"
            >
              <a target="_blank" :href="`/claim_periods/${cp.slug}/#f_doc`">
                <i class="fas fa-search fa-1x tw-text-gray-500 tw-mr-1" />
              </a>
              <a
                target="_blank"
                :href="`/finance_documents/${cp.finance_document.slug}/edit`"
              >
                <i class="fas fa-pencil fa-1x tw-text-gray-500 tw-mr-1" />
              </a>
            </div>
          </td>

          <!-- Value -->
          <td
            class="
              tw-px-4
              tw-py-2
              tw-whitespace-no-wrap
              tw-text-sm
              tw-leading-5
              tw-text-gray-500
              tw-text-center
            "
          >
            £<span
              v-show="cp.claim_value !== undefined"
              v-text="nFormatter(cp.claim_value, 1)"
            />
          </td>

          <!-- Progress -->
          <td
            :class="[
              'tw-px-4 tw-py-2 tw-whitespace-no-wrap tw-text-sm tw-leading-5 tw-text-gray-500 tw-text-center',
              cp.ready_for_review == true ? 'tw-bg-green-100' : '',
            ]"
          >
            <div v-show="cp.ready_for_review != true" v-cloak>
              <span
                v-show="cp.claim_progress !== undefined"
                v-text="cp.claim_progress + '%'"
              />
            </div>

            <!-- <span v-text="JSON.stringify(cp.feedbacks)"></span> -->

            <div v-show="cp.ready_for_review == true" v-cloak>
              <!-- RFR -->
              <span
                v-show="
                  cp.ready_for_review == true &&
                    cp.feedbacks !== undefined &&
                    cp.feedbacks.has_feedback == false
                "
              >
                <Tippy content="Ready for review, no feedback">
                  <i class="fas fa-check fa-1x tw-text-gray-500" />
                </Tippy>
              </span>

              <span
                v-show="
                  cp.ready_for_review == true &&
                    cp.feedbacks !== undefined &&
                    cp.feedbacks.has_feedback == true &&
                    cp.feedbacks.has_unresolved_feedback == true
                "
              >
                <Tippy content="Has unresolved feedback">
                  <i class="fas fa-check fa-1x tw-text-gray-500" />
                </Tippy>
              </span>

              <span
                v-show="
                  cp.ready_for_review == true &&
                    cp.feedbacks !== undefined &&
                    cp.feedbacks.has_feedback == true &&
                    cp.feedbacks.has_unresolved_feedback == false
                "
              >
                <Tippy content="All feedback resolved"> 💥 </Tippy>
              </span>
            </div>
          </td>

          <td
            class="
              tw-px-4
              tw-py-2
              tw-whitespace-no-wrap
              tw-text-sm
              tw-text-gray-500
              tw-text-center
            "
          >
            <span v-text="cp.last_user_activity_time_as_words" />
          </td>
          <!-- Badges -->
          <td
            class="
              tw-px-4
              tw-py-2
              tw-whitespace-no-wrap
              tw-text-sm
              tw-text-gray-500
              tw-text-center
            "
          >
            <div class="tw-flex tw-justify-around tw-gap-x-1">
              <div
                v-show="cp.emp_reviewer.name !== undefined"
                :title="`Being reviewed by ${cp.emp_reviewer.name}`"
              >
                <i class="fas fa-book-reader fa-1x tw-text-gray-500" />
              </div>

              <div v-show="cp.submission.status !== undefined">
                <a :href="`/submission/${cp.submission.slug}`" target="_blank">
                  <Tippy
                    :content="`Submission status: ${cp.submission.status}`"
                  >
                    <i class="fas fa-file-export fa-1x tw-text-gray-500" />
                  </Tippy>
                </a>
              </div>

              <div v-show="cp.has_unresolved_feedback === true">
                <Tippy :content="`Claim has unresolved feedback`">
                  <i class="fas fa-comment-alt-exclamation fa-1x tw-text-red-500" />
                </Tippy>
              </div>

              <div v-show="cp.terminated_or_abandoned == true">
                <Tippy content="Claim is terminated or abandoned">
                  <i class="fas fa-do-not-enter fa-1x tw-text-yellow-500" />
                </Tippy>
              </div>

              <div v-if="Object.keys(cp.cost).length > 0 && cp.cost.integration.slug !== undefined">
                <a :href="`/integrations/xero/${cp.cost.integration.slug}`"><img src="/images/xero-logo.blue.png" class="tw-h-4 tw-w-4" alt="This cost is linked to an accounting system"></a>
              </div>
            </div>
          </td>
          <td>
            <div class="tw-relative tw-inline-block">
              <dropdown>
                <template #link>
                  <div class="tw-cursor-pointer">
                    <i class="fas fa-ellipsis-v-alt tw-mx-2" />
                  </div>
                </template>

                <template #items>
                  <div
                    v-cloak
                    :class="['tw-origin-top-right tw-right-0 tw-z-10', theme.dropDown.menuDiv]"
                  >
                    <span
                      :class="[theme.dropDown.itemDiv]"
                      @click="fetchReviewCommentHistory(cp.slug)"
                    >
                      <i
                        class="fas fa-ellipsis-v-alt tw-text-center tw-w-3 tw-mr-2"
                      />
                      Review History
                    </span>

                    <a
                      v-show="showHubspotDealLink(cp)"
                      :class="[theme.dropDown.itemDiv]"
                      target="_blank"
                      :href="`https://app.hubspot.com/contacts/5102898/deal/${cp.hubspot_deal_id}/`"
                    >
                      <i class="fas fa-link tw-text-center tw-w-3 tw-mr-2" />
                      View on Hubspot
                    </a>

                    <span
                      v-show="showHubspotDealLink(cp)"
                      :class="[theme.dropDown.itemDiv]"
                      @click="triggerHubspotSync(cp.id)"
                    >
                      <i class="fas fa-sync tw-text-center tw-w-3 tw-mr-2" />
                      Refresh Hubspot
                    </span>

                    <a
                      v-show="!showHubspotDealLink(cp)"
                      :class="[theme.dropDown.itemDiv]"
                      target="_blank"
                      :href="`/claim_periods/${cp.id}/edit#claim_period_hubspot_deal_id`"
                    >
                      <i class="fas fa-link tw-text-center tw-w-3 tw-mr-2" />
                      Set a Hubspot Deal
                    </a>

                    <div v-show="cp.feedback_visible_to_client == true">
                      <span
                        :class="[theme.dropDown.itemDiv]"
                        @click="fetchEmailToCustomerTemplate(cp.slug)"
                      >
                        <i class="fas fa-envelope tw-text-center tw-w-3 tw-mr-2" />
                        Send Feedback to Client
                      </span>
                    </div>

                    <div v-show="cp.feedback_visible_to_client != true">
                      <span
                        :class="[theme.dropDown.itemDiv]"
                      >
                        <i class="fas fa-envelope tw-text-center tw-w-3 tw-mr-2" />
                        Feedback not visible
                      </span>
                    </div>

                    <div>
                      <a
                        :href="`/claim_periods/intensive_sme_info/${cp.id}/edit`"
                        :class="[theme.dropDown.itemDiv]"
                      >
                        <i class="fas fa-plus tw-text-center tw-w-3 tw-mr-2" />
                        Add Intensive SME Info
                      </a>
                    </div>

                    <div>
                      <a
                        :href="`/projects/?claim_period_id=${cp.id}`"
                        data-method="POST"
                        :class="[theme.dropDown.itemDiv]"
                      >
                        <i class="fas fa-plus tw-text-center tw-w-3 tw-mr-2" />
                        Add Project
                      </a>
                    </div>

                    <div
                      v-if="
                        companyAuthAgentSent(cp.company) == false &&
                          companyAuthAgentComplete(cp.company) == false
                      "
                    >
                      <a
                        :href="`/documents/authorisation_agent_send/${cp.slug}`"
                        :class="[theme.dropDown.itemDiv]"
                      >
                        <i
                          class="
                            fas
                            fa-envelope-open-text
                            tw-text-center tw-w-3 tw-mr-2
                          "
                        />
                        Send Auth Agent Request
                      </a>
                    </div>

                    <div>
                      <a
                        :href="`/claim_periods/${cp.slug}/claim_readiness`"
                        :class="[theme.dropDown.itemDiv]"
                      >
                        <i class="fas fa-tasks tw-text-center tw-w-3 tw-mr-2" />
                        Claim Readiness
                      </a>
                    </div>

                    <div>
                      <a
                        :href="`/claim_periods/${cp.slug}/timeline`"
                        :class="[theme.dropDown.itemDiv]"
                        target="_blank"
                      >
                        <i class="fas fa-user-clock tw-text-center tw-w-3 tw-mr-2" />
                        Activity Timeline
                      </a>
                    </div>
                    <div v-show="cp.submission">
                      <a
                        :href="`/submission/${cp.submission.slug}/`"
                        :class="[theme.dropDown.itemDiv]"
                      >
                        <i
                          class="fas fa-paper-plane tw-text-center tw-w-3 tw-mr-2"
                        />
                        Go to Submission
                      </a>
                    </div>

                    <div v-show="clientSubmitting(cp) && cp.submission.slug !== undefined">
                      <a
                        :href="`/submission/${cp.submission.slug}/record_claim_submission`"
                        :class="[theme.dropDown.itemDiv]"
                      >
                        <span>
                          <i class="fas fa-abacus tw-text-center tw-w-3 tw-mr-1" />
                          Confirm Submission (Customer)
                        </span>
                      </a>
                    </div>

                    <div v-show="cp.claim_benefit.slug !== undefined">
                      <a
                        :class="[theme.dropDown.itemDiv]"
                        target="_blank"
                        :href="`/claim_benefits/${cp.claim_benefit.slug}`"
                      >
                        <i class="far fa-coins tw-text-center tw-w-3 tw-mr-2" />
                        Go to Claim Benefit
                      </a>
                    </div>

                    <a
                      :class="[theme.dropDown.itemDiv]"
                      target="_blank"
                      :href="`/claim_periods/${cp.slug}/dashboard`"
                    >
                      <i class="fas fa-money-check-edit tw-text-center tw-w-3 tw-mr-2" />
                      Customer Dashboard
                    </a>
                  </div>
                </template>
              </dropdown>
            </div>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import { Tippy } from "vue-tippy";
import { nFormatter } from "../../../../shared/helpers.js";
import { documentCsrfToken } from "../../../../shared/http.js";
import { SORT, dynamicSort } from "../../../../shared/tables_sort.js";
import { css } from "../../../../shared/theme.js";

export default {
  components: { Tippy },
  props: {
    query: {
      type: String,
      default: ""
    },
    claims: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      sortOrder: true,
      theme: css,
      sortedColumn: "",
      claimsProxy: [],
      subMenuClaimPeriod: 0,
      rowColoursGreen: {
        status: cp => cp?.status?.ready_for_review === true,
        projects: cp => (cp?.projects?.length || 0) > 0
          && cp?.projects?.length
            === cp?.projects?.filter(p => p.ready_for_review === true)?.length,
        costs: cp => cp?.cost?.ready_for_review === true,
        rad: cp => cp?.cost?.pc_rd_ready_for_review === true,
        fds: cp => cp?.finance_document?.ready_for_review === true
      }
    };
  },
  watch: {
    claims(val) {
      this.claimsProxy = val;
    }
  },
  created() {
    this.debounceToggleFlagAPI = _.debounce(this.toggleFlagAPI, 500);
  },
  methods: {
    triggerHubspotSync(claim_period_id) {
      fetch(`/api/claim_periods/${claim_period_id}/sync_hubspot`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": documentCsrfToken()
        }
      }).then(() => { alert("This will refresh in the background.  Reload this page shortly if you need to see the change."); });
    },
    companyAuthAgentSent(company) {
      return company.auth_agent_requested;
    },
    companyAuthAgentComplete(company) {
      return company.auth_agent_completed;
    },
    companyAmlCheckText(cp) {
      let string = "";
      string
        += `Company AML is ${cp.company.aml_check_results.status}<br />`;

      if (
        cp.company.auth_agent_requested == false
        && cp.company.auth_agent_completed == false
      ) {
        string
          += `<br /><a class='tw-text-white hover:tw-text-white hover:tw-underline' href='/documents/authorisation_agent_send/${
            cp.slug
          }' target='_blank'>`
          + "Not requested Auth Agent"
          + "</a>";
      }

      if (cp.company.auth_agent_completed == false) {
        string
          += `<br /><a class='tw-text-white hover:tw-text-white hover:tw-underline' href='/documents/authorisation_agent/${
            cp.slug
          }' target='_blank'>`
          + "Not uploaded Auth Agent"
          + "</a>";
      }

      return string;
    },
    companyAmlCheckColor(company) {
      if (company.auth_agent_completed == false) {
        return "tw-text-red-500";
      }

      if (company.aml_check_results.status == "GREEN") {
        return "tw-text-green-300";
      } else if (company.aml_check_results.status == "RED") {
        return "tw-text-red-300";
      } else if (company.aml_check_results.status == "AMBER") {
        return "tw-text-orange-300";
      }
    },
    flagCssClass(flagValue) {
      return flagValue
        ? "tw-text-red-800 tw-animate-pulse"
        : "tw-text-gray-200";
    },
    toggleFlag(claim_period, flag_value) {
      claim_period.flagged = flag_value;
      this.debounceToggleFlagAPI(claim_period, flag_value);
    },
    toggleFlagAPI(claim_period, flag_value) {
      fetch(`/api/claim_periods/${claim_period.slug}/update_attribute`, {
        method: "PATCH",
        body: JSON.stringify({ flagged: flag_value })
      });
    },
    nFormatter(num, digits) {
      return nFormatter(num, digits);
    },
    showHubspotDealLink(cp) {
      return cp.hubspot_deal_id ?? "" !== "";
    },
    setSortColumn(object, property, sortType) {
      const sortedColString = `${String(object)}.${property.toString()}`;
      if (sortedColString === this.sortedColumn) {
        this.sortOrder = !this.sortOrder;
      }
      this.sortedColumn = sortedColString;
      this.claimsProxy = dynamicSort(
        this.claimsProxy,
        object,
        property,
        this.sortOrder,
        sortType
      );
    },
    sortedStyle(columnName) {
      if (this.sortedColumn === columnName) {
        return this.sortOrder ? "tw-border-red-400" : "tw-border-green-500";
      } else {
        return "";
      }
    },
    sortBoolean(sortFunction) {
      return (a, b) => (sortFunction(a) === sortFunction(b) ? 0 : sortFunction(a) ? -1 : 1);
    },
    sortByRowColour(object, property) {
      const sortFunc = this.sortBoolean(this.rowColoursGreen[object]);
      this.sortedColumn = `${object}.${property}`;
      this.sortOrder = !this.sortOrder;

      this.claimsProxy = dynamicSort(
        this.claimsProxy,
        object,
        property,
        this.sortOrder,
        SORT.CUSTOM,
        sortFunc
      );
      /* if(this.sortOrder) { */
      /* this.claimsProxy?.sort(sortFunc).reverse(); */
      /* } else { */
      /* this.claimsProxy?.sort(sortFunc); */
      /* } */
    },
    sortLastUserActivity() {
      this.sortedColumn = "undefined.last_user_activity_time_as_epoch";
      this.sortOrder = !this.sortOrder;

      const noActivityTime = _.chain(this.claimsProxy)
        .reject(c => c.last_user_activity_time_as_epoch > 0)
        .value();

      const hasActivityTime = _.chain(this.claimsProxy)
        .filter(c => c.last_user_activity_time_as_epoch > 0)
        .value();

      const sorted = this.sortOrder
        ? _.chain(hasActivityTime).sortBy(
          c => c.last_user_activity_time_as_epoch
        )
        : _.chain(hasActivityTime)
            .sortBy(c => c.last_user_activity_time_as_epoch)
            .reverse();

      this.claimsProxy = _.concat(sorted.value(), noActivityTime);
    },
    setSubMenuClaimPeriod(subMenuClaimPeriod) {
      this.subMenuClaimPeriod = subMenuClaimPeriod;
    },
    resetSubMenuClaimPeriod() {
      this.setSubMenuClaimPeriod("");
    },
    fetchReviewCommentHistory(slug) {
      window.triggerModal({
        url: `/claim_periods/${slug}/review_comment_history.html`,
        title: "Review History"
      });
    },
    fetchEmailToCustomerTemplate(slug) {
      window.triggerModal({
        url: `/claim_periods/${slug}/send_feedback_to_customer.html`,
        title: "Send Feedback Email to Customer"
      });
    },
    clientSubmitting(cp) {
      return cp.finance_document.submit_by_emp !== true;
    },
    projectsPopup(cp) {
      return cp.projects
        .map(x => (
          `<a class="tw-text-white hover:tw-text-gray-300" target=_blank href=/projects/${
            x.slug}/build/project`
            + `>${
              x.title
            }</a><br />`
        ))
        .join("");
    },
    onboardedIcon(onboarded) {
      return onboarded
        ? "tw-text-green-600 far fa-check"
        : "tw-text-red-600 far fa-times";
    }
  }
};
</script>
